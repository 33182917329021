<template>
<div>
    <Header />

     <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>Our Work</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-10 bg-gradient arrow-bottom">
            <div class="container">

                <div id="dignificacion" class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col mb-4 mb-md-0 text-center">
                        <img src="@/assets/img/biblioteca1.jpg" alt="Bibliotecas Rebeca Lan" class="img-fluid shadow rounded">
                    </div>
                    <div class="col d-flex align-items-center">
                        <div>
                            <h2 class="h3"><b>Dignification in Prisons</b></h2>
                            <p class="lead">We create access to give the women tools such as building libraries for personal growth.<br>-Rashel Cohen Lan</p>
                            <p>It is important that women have a space of their own while in prison, to benefit their social reintegration process.</p>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col">
                        <p class="text-secret"><b>Rebeca Lan Libraries</b></p>
                        <p>
                            We fund the <b>Rebeca Lan Libraries</b> within prisons. Books open doors for readers to travel to new settings and give the women freedom through reading. Each of the donated books from the community contains an inspiring message written by the society.</p>
                    </div>
                    <div class="col">
                        <p class="text-secret"><b>Playroom and bedrooms for children and their mothers</b></p>
                        <p>In Mexico there are more than 500 boys and girls who live in prison under the care of their mothers for a period of three years and eleven months. These <b>boys and girls</b> require greater protection and care in order to <b>develop healthily</b>  and aspire to dignified life.</p>
                        <p>In the <b>Rebeca Lan Playing Centers</b>, spaces are set up for boys and girls to spend quality time with their mothers. These centers strengthen mother and children by modeling a life similar to that of a child outside of prison.</p>
                        <p>The <b>Rebeca Lan Bedrooms</b> are specific spaces created to strengthen the safety and mental development of children who live with their mothers in confinement. They are equipped ad a friendly and comfortable place for their rest.</p>
                    </div>
                </div>


                <div id="sanacion" class="row row-cols-1 row-cols-md-2 py-10">
                    <div class="col">
                        <h2 class="h3"><b>Healing and <br>Emotional Intelligence</b></h2>
                        <p>Women enter the system they feel anguished, full of uncertainty, and often depressed.</p>
                        <p>The <b>Rebeca Lan Foundation</b> has developed workshops, conferences, and programs that have allowed these women to develop the necessary skills to help them bounce back into society. <b>Music</b> has been pivotal in improving their lives. Through the work of the foundation as well as music, we have created an <b>orchestra with over 30 donated instruments</b>, lessons where the women can use their mind, body and soul in expressing themselves.</p>
                    </div>
                    <div class="col text-center">
                        <img src="@/assets/img/orquesta2.jpg" alt="Orquesta Rebeca Lan" class="img-fluid shadow rounded">
                    </div>
                </div>
            </div>
        </div>

        <div id="vinculacion" class="py-10 bg-gradient">
            <div class="container">
                <div id="vinculo" class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col mb-4 mb-md-0">
                        <img src="@/assets/img/familia.jpg" alt="Familia" class="img-fluid shadow rounded">
                    </div>
                    <div class="col d-flex align-items-center">
                        <div>
                            <h2 class="h3"><b>Family bond and motherhood</b></h2>
                            <p class="lead">When the family is united, difficulties can be overcome.<br>-Rashel Cohen Lan</p>
                            <p>The family of imprisoned women in Mexico is affected because the relationship of <b>mothers with their children</b> is broken from the moment of separation. Maintaining these <b>family ties</b> helps them overcome moments of crisis. We work to <b>rebuild and strengthen</b> these ties.</p>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-2 mb-5">
                    <div class="col mb-5">
                        <p class="text-secret"><b>Family reunion</b></p>
                        <p>In the prisons, 9 out of 10 women do not receive visitors. Since so many of the women are lonely, we try to foster stronger communication. Through our program <b>we encourage family visits which foster bonding</b>. Some examples of the family support we have offered are backpack drives for the kids, annual birthday celebrations, and children's field trips.</p>
                    </div>
                    <div class="col mb-5">
                        <p class="text-secret"><b>First Years Program with Rebeca Lan</b></p>
                        <p>The boys and girls who live in prison with their mothers are in space that has the stigmas of a prison, most do not have basic needs and do not have the same opportunities as infants and children who live normal lives. To contribute to <b>their well-being</b>, this program organizes recreational activities within the prisons, as well as tours to recreational places.</p>
                    </div>
                    <div class="col mb-5">
                        <p class="text-secret"><b>Connecting families at a distance</b></p>
                        <p>We seek to strengthen family ties through video calls and encourage letter writing. We personally bring letters from the families and hand deliver them inside the prison. <b>Family engagement</b> is always promoted by bringing the children in once the women have completed their courses. The mothers feel so proud of themselves once they finish the courses that we offer, and feel so confident and excited to see their families again to share in their accomplishments.</p>
                        <p>We provide family therapy before and after separation. The kids benefit tremendously from having a counselor during this time of need.</p>
                    </div>
                    <div class="col">
                        <p class="text-secret"><b>Therapy before and after separation</b></p>
                        <p>One of the most difficult situations faced by <b>women in prison and their children</b> is separation when the little ones turn 3 and have to be separated from their mothers. We offer therapy to the mother and the children before and after the separation, thought this separation will not stop being painful, we help them to be able to go through this process.</p>
                    </div>
                </div>

                <div id="inclusion" class="inclusion py-10">
                    <div class="row row-cols-1 row-cols-md-2 mb-5">
                        <div class="col-lg-8">
                            <div>
                            <h2><b>Labor inclusion</b></h2>
                            <p class="lead">Nothing is impossible for women, it only takes her time to achieve it.<br>-Rashel Cohen Lan</p>
                            <p>Only 5% of women who leave the prison are able to secure a job. We are able to help them navigate these challenges and help them form a plan for a substantial future life outside of the prison. <b>We create alliances with the public and private sector to help with a job plan.</b></p>
                            </div>
                            <!-- <br><br>
                            <div>
                                <p class="text-secret"><b>I BELIEVE IN YOU Lan Woman</b></p>
                                <p>We facilitate workshops and training inside the <b>prison</b> preparing <b>women</b> so that they can get a decent job when they are released. We make alliances with public and private initiative and civil society so that they receive them with a job opportunity.</p>
                            </div> -->
                        </div>
                        <div class="col-lg-4 text-center">
                        <img src="@/assets/img/maria-jasjane.jpg" alt="Maria Jasjane" class="img-fluid shadow rounded">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/nosotros.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/en/Templates/Header'
import Footer from '@/modules/en/Templates/Footer'

export default {
    name: "NuestroTrabajo",
    components:{
        Header,
        Footer
    },
    mounted(){
       if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
</script>

